import * as React from 'react';
import { GridDataHooks, GridDataKeys } from '../DataHooks';
import { classes } from '../Grid.st.css';
import { DEFAULT_COLUMN_SPAN, DEFAULT_ROW_SPAN } from '../constants';
import { GridItemDefaultProps, GridItemProps } from '../types';

export class Item extends React.PureComponent<GridItemProps> {
  static displayName = 'Item';
  static defaultProps: GridItemDefaultProps = {
    colSpan: DEFAULT_COLUMN_SPAN,
    rowSpan: DEFAULT_ROW_SPAN,
  };

  itemRef: React.RefObject<HTMLLIElement>;

  constructor(props: GridItemProps) {
    super(props);
    this.itemRef = React.createRef();
  }

  private getItemDataAttributes({ rowSpan, colSpan }: any) {
    return {
      [GridDataKeys.rowSpan]: rowSpan,
      [GridDataKeys.columnSpan]: colSpan,
    };
  }

  componentDidMount() {
    if (this.props.focused) {
      this.itemRef.current?.focus({ preventScroll: true });
    }
  }

  render() {
    const { children, rowSpan, colSpan } = this.props;
    return (
      <li
        ref={this.itemRef}
        tabIndex={-1}
        data-hook={GridDataHooks.item}
        className={classes.item}
        style={{
          gridColumnEnd: `span ${colSpan}`,
          gridRowEnd: `span ${rowSpan}`,
        }}
        {...this.getItemDataAttributes({
          rowSpan,
          colSpan,
        })}
      >
        {children}
      </li>
    );
  }
}
