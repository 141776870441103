import React from 'react';
import loadable from '@wix/yoshi-flow-editor/loadable';
import { WidgetViewModel } from '../../../../viewModel/viewModel';
import { PremiumDialog } from './PremiumDialog/PremiumDialog';
import {
  useBi,
  useEnvironment,
  useExperiments,
  useTranslation,
} from '@wix/yoshi-flow-editor';
import { WidgetActions } from '../../../../actions/actions';
import { DialogType } from '../../../../viewModel/dialogViewModel/dialogViewModel';
import { NavigationTarget } from '../../../../actions/navigateToNextPage/consts';
import stylesParams from '../../stylesParams';
import { useStyles } from '@wix/tpa-settings/react';
import { classes } from './Dialog.st.css';
import type {
  Basket,
  ServicesPreferencesModalProps,
} from '@wix/bookings-services-preferences-modal/types';
import { servicesPreferencesModalKeysMap as servicesPreferencesModalFormatterKeysMap } from './constants';
import { WidgetName } from '../../../../utils/bi/consts';

export type DialogProps = {
  widgetActions: WidgetActions;
  widgetViewModel: WidgetViewModel;
};

const ServicesPreferencesModal: React.FC<ServicesPreferencesModalProps> =
  loadable(() => import('@wix/bookings-services-preferences-modal'));

export const Dialog: React.FC<DialogProps> = (props) => {
  const { experiments } = useExperiments();
  const { t } = useTranslation();
  const { isMobile, isRTL } = useEnvironment();
  const { widgetActions, widgetViewModel } = props;
  const { dialogViewModel } = widgetViewModel;
  const { onPreferencesModalServiceSelected } = widgetActions;
  const styles = useStyles();
  const biLogger = useBi();

  if (DialogType.Premium === dialogViewModel.type) {
    return (
      <PremiumDialog
        isOpen={dialogViewModel.isOpen}
        content={t('user-message.not-bookable')}
        confirmationButtonLabel={t('user-message.action-ok-label')}
        onClose={() => widgetActions.onDialogClose()}
      />
    );
  }

  if (
    DialogType.ServicePreferences === dialogViewModel.type &&
    dialogViewModel.isOpen
  ) {
    return (
      <React.Suspense fallback={null}>
        <ServicesPreferencesModal
          className={classes.servicesPreferencesModal}
          {...dialogViewModel.data!}
          widgetStyles={{
            primaryColor: styles.get(stylesParams.bookButtonBackgroundColor)
              .value,
          }}
          t={t}
          onConfirm={(basket: Basket) => {
            widgetActions.onDialogClose();
            widgetActions.navigateToNextPage({
              basket,
              target: NavigationTarget.CALENDAR_PAGE,
            });
          }}
          onClose={() => widgetActions.onDialogClose()}
          onServiceSelected={onPreferencesModalServiceSelected}
          isMobile={isMobile}
          isRTL={isRTL}
          biLogger={biLogger}
          formatterKeysMap={servicesPreferencesModalFormatterKeysMap}
          experiments={experiments}
          openedBy={WidgetName.PAGE}
        />
      </React.Suspense>
    );
  }

  return null;
};
