import React, { FC } from 'react';
import { useEnvironment } from '@wix/yoshi-flow-editor';
import { useVisibilityCheck } from '../../hooks/useVisibilityCheck';
import { Title } from './Title/Title';
import { ListFilter } from './ListFilter/ListFilter';
import { st, classes } from './Header.st.css';
import { DataHooks } from './consts';
import { HeaderViewModel } from '../../../../viewModel/headerViewModel/headerViewModel';

export interface HeaderProps {
  viewModel: HeaderViewModel;
}

export const Header: FC<HeaderProps> = ({ viewModel }) => {
  const { isMobile } = useEnvironment();
  const { shouldBeVisible } = useVisibilityCheck();
  const isTitleVisible = shouldBeVisible('isTitleVisible');
  const { isListFilterVisible } = viewModel;

  return isTitleVisible || isListFilterVisible ? (
    <div className={st(classes.root, { isMobile })} data-hook={DataHooks.ROOT}>
      {isTitleVisible ? <Title /> : null}
      {isListFilterVisible ? <ListFilter /> : null}
    </div>
  ) : null;
};
