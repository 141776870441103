import React, { FC } from 'react';
import { useSettings } from '@wix/tpa-settings/react';
import loadable from '@wix/yoshi-flow-editor/loadable';
import { classes } from './ListFilter.st.css';
import { FilterLayoutOptions } from '../../../../../types/types';
import { DataHooks } from './consts';
import settingsParams from '../../../settingsParams';

const DropdownFilter = loadable(
  () =>
    import(
      /* webpackChunkName: "DropdownFilter" */ './DropdownFilter/DropdownFilter'
    ),
);

const TagsFilter = loadable(
  () => import(/* webpackChunkName: "TagsFilter" */ './TagsFilter/TagsFilter'),
);

const TabsFilter = loadable(
  () => import(/* webpackChunkName: "TabsFilter" */ './TabsFilter/TabsFilter'),
);

function getFilterComponent(layout: FilterLayoutOptions) {
  switch (layout) {
    case FilterLayoutOptions.DROPDOWN:
      return <DropdownFilter />;
    case FilterLayoutOptions.TAGS:
      return <TagsFilter />;
    case FilterLayoutOptions.TABS:
      return <TabsFilter />;
  }
}

export const ListFilter: FC = () => {
  const settings = useSettings();
  const layout = settings.get(settingsParams.filterLayout);
  return (
    <div data-hook={DataHooks.WRAPPER} className={classes.root}>
      {getFilterComponent(layout)}
    </div>
  );
};
