import React, { FC } from 'react';
import { useEnvironment, useTranslation } from '@wix/yoshi-flow-editor';
import { useSettings, useStyles } from '@wix/tpa-settings/react';
import {
  Divider,
  Text,
  TextTypography as TYPOGRAPHY,
} from 'wix-ui-tpa/cssVars';

import { classes, st } from './CourseAvailability.st.css';
import { classes as serviceClasses } from '../ServiceInfo.st.css';
import settingsParams from '../../../../../settingsParams';
import { DataHooks } from '../consts';
import stylesParams from '../../../../../stylesParams';
import { useWidgetViewModel } from '../../../../hooks/useWidgetViewModel';
import { useVisibilityCheck } from '../../../../../hooks/useVisibilityCheck';
import { useAddVisibilityClass } from '../../../../../hooks/useAddVisibilityClass';

export interface Props {
  numberOfSpotsLeft?: number;
  checkDivider?: boolean;
  isTooLateToBook: boolean;
  isPassedEndDate: boolean;
  className?: string;
}

export const CourseAvailability: FC<Props> = ({
  numberOfSpotsLeft,
  checkDivider,
  isTooLateToBook,
  className,
}) => {
  const { t } = useTranslation();
  const settings = useSettings();
  const styles = useStyles();
  const { isMobile } = useEnvironment();
  const { shouldBeVisible } = useVisibilityCheck();
  const isCourseAvailabilityVisible = shouldBeVisible(
    'isCourseAvailabilityVisible',
  );
  const { addVisibilityClass } = useAddVisibilityClass();
  const { shouldWorkWithAppSettings } = useWidgetViewModel();
  const tagName = shouldWorkWithAppSettings
    ? styles.get(stylesParams.courseAvailabilityFont).htmlTag ||
      settings.get(settingsParams.courseAvailabilityFontHtmlTag)
    : settings.get(settingsParams.courseAvailabilityFontHtmlTag);
  const noDataYet = numberOfSpotsLeft === undefined;
  if (!isCourseAvailabilityVisible || isTooLateToBook) {
    return null;
  }

  const showFullyBooked =
    isCourseAvailabilityVisible && numberOfSpotsLeft! <= 0;

  const available =
    isCourseAvailabilityVisible &&
    numberOfSpotsLeft! > 0 &&
    isTooLateToBook === false;

  const displayNumberOfSpots =
    available && settings.get(settingsParams.displayNumberOfSpots);

  const withDivider =
    checkDivider && shouldBeVisible('isServiceDividerVisible');

  let customAvailabilityText: any;
  let courseAvailability = '';

  switch (true) {
    case showFullyBooked:
      courseAvailability = settings.get(
        settingsParams.courseNoAvailabilityText,
      );
      break;
    case noDataYet:
      courseAvailability = t('service.schedule.course.loading');
      break;
    case displayNumberOfSpots:
      customAvailabilityText = settings.get(
        settingsParams.courseAvailabilityText,
      );
      courseAvailability = customAvailabilityText
        ? `${numberOfSpotsLeft} ${customAvailabilityText}`
        : t('service.schedule.course.number-of-spots-left', {
            numberOfSpotsLeft,
          });
      break;
    case available:
      courseAvailability = settings.get(settingsParams.courseAvailableText);
      break;
  }
  return courseAvailability ? (
    <>
      {withDivider ? (
        <Divider
          data-hook={DataHooks.DIVIDER2}
          className={addVisibilityClass(
            serviceClasses.divider,
            serviceClasses.dividerVisibility,
            'isServiceDividerVisible',
          )}
        />
      ) : null}
      <Text
        data-hook={DataHooks.COURSE_AVAILABILITY}
        className={addVisibilityClass(
          st(
            classes.root,
            {
              isMobile,
            },
            className,
          ),
          classes.courseAvailabilityVisibility,
          'isCourseAvailabilityVisible',
        )}
        typography={TYPOGRAPHY.listText}
        tagName={tagName}
      >
        {courseAvailability}
      </Text>
    </>
  ) : null;
};
