import { ServiceInfoLayoutOption } from '../Body/ServiceCard/ServiceInfo/consts';
import { ServiceListLayoutOptions } from '../../../../types/types';
import { useWidgetViewModel } from './useWidgetViewModel';

export const useServiceInfoLayout = (): ServiceInfoLayoutOption => {
  const { serviceListLayout } = useWidgetViewModel();

  return serviceListLayout === ServiceListLayoutOptions.STRIP
    ? ServiceInfoLayoutOption.HORIZONTAL
    : ServiceInfoLayoutOption.VERTICAL;
};
