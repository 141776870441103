import React, { FC } from 'react';
import { Text, TextTypography as TYPOGRAPHY } from 'wix-ui-tpa/cssVars';
import { useSettings, useStyles } from '@wix/tpa-settings/react';
import {
  useEnvironment,
  useExperiments,
  useTranslation,
} from '@wix/yoshi-flow-editor';

import { DataHooks } from './consts';
import { classes, st } from './Title.st.css';
import stylesParams from '../../../stylesParams';
import settingsParams from '../../../settingsParams';
import { useWidgetViewModel } from '../../hooks/useWidgetViewModel';
import { useAddVisibilityClass } from '../../../hooks/useAddVisibilityClass';
import { useLayoutPerBpInWidget } from '../../../hooks/useLayoutPbpInWidget';

export type TitleProps = {};

export const Title: FC = () => {
  const settings = useSettings();
  const styles = useStyles();
  const { isMobile } = useEnvironment();
  const { experiments } = useExperiments();
  const { layoutStylePerBpEnable } = useLayoutPerBpInWidget();
  const { t } = useTranslation();
  const { shouldWorkWithAppSettings } = useWidgetViewModel();
  const { addVisibilityClass } = useAddVisibilityClass();
  const tagName = shouldWorkWithAppSettings
    ? styles.get(stylesParams.widgetTitleFont).htmlTag ||
      settings.get(settingsParams.widgetTitleFontHtmlTag)
    : settings.get(settingsParams.widgetTitleFontHtmlTag);
  return (
    <div
      data-hook={DataHooks.WRAPPER}
      className={st(
        classes.root,
        {
          alignment: settings.get(settingsParams.titleAlignment),
          isMobile,
          isLayoutPBPEnabled: layoutStylePerBpEnable,
        },
        addVisibilityClass('', classes.titleVisibility, 'isTitleVisible'),
      )}
    >
      <Text
        className={classes.title}
        data-hook={DataHooks.TITLE}
        typography={TYPOGRAPHY.largeTitle}
        tagName={tagName}
      >
        {settings.get(settingsParams.titleText)}
      </Text>
    </div>
  );
};
