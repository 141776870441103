import { TimezoneType } from '@wix/bookings-uou-types';
import { BusinessInfo as LegacyBusinessInfo } from '../../../legacy/types';

export const getDefaultTimezone = (businessInfo?: LegacyBusinessInfo) => {
  const localTimezone = new Intl.DateTimeFormat().resolvedOptions().timeZone;
  const businessTimezone = businessInfo?.timeZone;
  const defaultTimezoneType = businessInfo?.timezoneProperties?.defaultTimezone;
  return defaultTimezoneType === TimezoneType.CLIENT
    ? localTimezone
    : businessTimezone;
};
