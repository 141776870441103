import React, { type FC } from 'react';
import { sdk as imageSDK } from '@wix/image-kit';
import {
  ImageHoverEffectOptions as HoverEffectOptions,
  ImageLoadingBehaviorOptions as LoadingBehaviorOptions,
  ImageResizeOptions as ResizeOptions,
  ThumbnailImage,
} from 'wix-ui-tpa/cssVars';
import { ImageResizeOptions } from '../../../../../../types/types';
import { useWidgetViewModel } from '../../../hooks/useWidgetViewModel';
import { classes, st } from './ServiceImage.st.css';
import { DataHooks } from './consts';
import { Image } from '@wix/ambassador-bookings-services-v2-service/types';

export type ServiceImageProps = {
  image?: Image;
  imageTitle: string;
  width: number;
  height: number;
  onClick: () => void;
  className?: string;
};

const mapImageResizeOptionsToResizeOptions = {
  [ImageResizeOptions.CROP]: ResizeOptions.cover,
  [ImageResizeOptions.FIT]: ResizeOptions.contain,
};

export const ServiceImage: FC<ServiceImageProps> = (props) => {
  const { image, height, onClick, className } = props;
  const { isSEO, serviceImageViewModel, serviceListLayout } =
    useWidgetViewModel();
  const { imageResize, aspectRatio, focalPoint, isRoundGrid } =
    serviceImageViewModel;
  const width = isRoundGrid ? height : props.width;

  const getImageUrl = () => {
    if (image) {
      const imageDimensions = isSEO
        ? {
            width: isRoundGrid ? image?.height : image?.width,
            height: image?.height,
          }
        : { width, height };
      const targetWidth = imageDimensions.width || 0;
      const targetHeight = imageDimensions.height || 0;
      return imageResize === ImageResizeOptions.CROP
        ? imageSDK.getScaleToFillImageURL(
            image.url!,
            image.width!,
            image.height!,
            targetWidth,
            targetHeight,
            { focalPoint },
          )
        : imageSDK.getScaleToFitImageURL(
            image.url!,
            image.width!,
            image.height!,
            targetWidth,
            targetHeight,
          );
    }
  };

  return (
    <div
      data-hook={DataHooks.ROOT}
      className={st(
        classes.root,
        {
          shape: isRoundGrid ? 'round' : 'square',
          layout: serviceListLayout,
        },
        className,
      )}
    >
      <div
        data-hook={DataHooks.WRAPPER}
        className={classes.wrapper}
        style={{
          height: isRoundGrid ? `calc(100% - 32px)` : undefined,
          width: isRoundGrid ? `calc(${100 / aspectRatio}% - 32px)` : undefined,
        }}
      >
        <div
          data-hook={DataHooks.CONTAINER}
          onClick={onClick}
          className={classes.clickable}
          role="presentation"
          tabIndex={-1}
        >
          {image ? (
            <ThumbnailImage
              key={`image-key-${height}-${width}-${imageResize}-${focalPoint.x}-${focalPoint.y}-${aspectRatio}`}
              alt={image.altText || undefined}
              fluid
              width={width}
              height={height}
              focalPoint={focalPoint}
              loadingBehavior={LoadingBehaviorOptions.none}
              aspectRatio={aspectRatio}
              hoverEffect={HoverEffectOptions.darken}
              resize={mapImageResizeOptionsToResizeOptions[imageResize]}
              data-hook={DataHooks.IMAGE}
              className={classes.image}
              src={getImageUrl()}
              sourceHeight={image.height}
              sourceWidth={image.width}
              shouldUseLQIP={true}
              isSEOBot={isSEO}
            />
          ) : null}
        </div>
      </div>
    </div>
  );
};
