import React from 'react';
import { WidgetViewModel } from '../../../../viewModel/viewModel';

export const WidgetViewModelContext = React.createContext<WidgetViewModel>(
  {} as WidgetViewModel,
);

export const WidgetViewModelConsumer = WidgetViewModelContext.Consumer;
export const WidgetViewModelProvider = WidgetViewModelContext.Provider;

export const useWidgetViewModel = () =>
  React.useContext(WidgetViewModelContext);
