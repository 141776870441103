import React, { FC } from 'react';
import { useEnvironment } from '@wix/yoshi-flow-editor';
import { useSettings, useStyles } from '@wix/tpa-settings/react';
import { Text, TextTypography as TYPOGRAPHY } from 'wix-ui-tpa/cssVars';
import { classes, st } from './Title.st.css';
import { DataHooks } from './consts';
import stylesParams from '../../../../../stylesParams';
import { useWidgetViewModel } from '../../../../hooks/useWidgetViewModel';
import settingsParams from '../../../../../settingsParams';

export type TitleProps = {
  title: string;
  url?: string;
  onClick: () => void;
  tabIndex?: number;
  ariaDescribedBy?: string;
};

export const Title: FC<TitleProps> = ({
  title,
  url,
  onClick,
  ariaDescribedBy,
  tabIndex = 0,
}) => {
  const { isMobile, isViewer } = useEnvironment();
  const styles = useStyles();
  const settings = useSettings();
  const { shouldWorkWithAppSettings } = useWidgetViewModel();
  const tagName = shouldWorkWithAppSettings
    ? styles.get(stylesParams.serviceNameFont).htmlTag ||
      settings.get(settingsParams.serviceNameFontHtmlTag)
    : settings.get(settingsParams.serviceNameFontHtmlTag);

  const getHrefAttribute = () => (isViewer ? { href: url } : {});

  return (
    <div
      className={st(classes.root, { isMobile })}
      data-hook={DataHooks.ROOT}
      onClick={(e) => {
        e.stopPropagation();
        e.preventDefault();
        onClick();
      }}
    >
      <a
        {...getHrefAttribute()}
        data-hook={DataHooks.LINK}
        className={classes.link}
        role="link"
        tabIndex={tabIndex}
        aria-label={title}
        aria-describedby={ariaDescribedBy}
      >
        <Text
          className={classes.title}
          data-hook={DataHooks.TEXT}
          typography={TYPOGRAPHY.smallTitle}
          tagName={tagName}
        >
          {title}
        </Text>
      </a>
    </div>
  );
};
