import React, { FC } from 'react';
import { NewGrid } from '../../NewGrid/NewGrid';
import { GridItem } from '../../NewGrid/GridItem/GridItem';
import { ServiceCard } from '../ServiceCard/ServiceCard';
import { DEFAULT_IMAGE_CONTAINER, SERVICE_INFO_LABEL_ID } from '../consts';
import { EnrichedService } from '../../../../../types/types';
import { useTranslation } from '@wix/wix-i18n-config';
import { useWidgetViewModel } from '../../hooks/useWidgetViewModel';

export interface SectionGridProps {
  services: EnrichedService[];
  dataHook: string;
  title?: string;
  id?: string;
}

export const SectionGrid: FC<SectionGridProps> = ({
  services,
  dataHook,
  title,
  id,
}) => {
  const { t } = useTranslation();
  const { bodyViewModel } = useWidgetViewModel();
  const { focusCardIndex } = bodyViewModel;
  const { width, height } = DEFAULT_IMAGE_CONTAINER;
  return (
    <NewGrid
      title={title}
      dataHook={dataHook}
      id={id}
    >
      <span style={{ display: 'none' }} id={SERVICE_INFO_LABEL_ID}>
        {t('offering.info.aria-title')}
      </span>
      {services.map((service, idx) => (
        <GridItem key={`${service.id}-${idx}`} focused={idx === focusCardIndex}>
          <ServiceCard
            service={service}
            width={width}
            height={height}
            index={idx}
          />
        </GridItem>
      ))}
    </NewGrid>
  );
};
