import React from 'react';
import {
  Button,
  Dialog as DialogComponent,
  HoverStyles,
  Text,
  TextTypography as TYPOGRAPHY,
} from 'wix-ui-tpa/cssVars';
import { classes, st } from './PremiumDialog.st.css';
import { useEnvironment } from '@wix/yoshi-flow-editor';
import { DataHooks } from './consts';

export type PremiumDialogProps = {
  isOpen: boolean;
  content: string;
  confirmationButtonLabel: string;
  onClose: () => void;
};

export const PremiumDialog: React.FC<PremiumDialogProps> = (props) => {
  const { isMobile } = useEnvironment();
  const { isOpen, content, confirmationButtonLabel, onClose } = props;

  return (
    <DialogComponent
      data-hook={DataHooks.WRAPPER}
      isOpen={isOpen}
      onClose={onClose}
      className={st(classes.root, { isMobile })}
      notFullscreenOnMobile
    >
      <Text
        className={classes.content}
        data-hook={DataHooks.CONTENT}
        typography={TYPOGRAPHY.runningText}
      >
        {content}
      </Text>
      <Button
        className={classes.button}
        onClick={onClose}
        data-hook={DataHooks.CONFIRMATION_BUTTON}
        fullWidth={isMobile}
        hoverStyle={HoverStyles.underline}
      >
        {confirmationButtonLabel}
      </Button>
    </DialogComponent>
  );
};
