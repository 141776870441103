import * as React from 'react';
import { classes } from '../NewGrid.st.css';

export interface GridItemProps {
  focused?: boolean;
}
export class GridItem extends React.PureComponent<GridItemProps> {
  itemRef: React.RefObject<HTMLLIElement>;

  constructor(props: GridItemProps) {
    super(props);
    this.itemRef = React.createRef();
  }

  componentDidMount() {
    if (this.props.focused) {
      this.itemRef.current?.focus({ preventScroll: true });
    }
  }

  render() {
    const { children } = this.props;
    return (
      <li
        ref={this.itemRef}
        tabIndex={-1}
        data-hook="Grid-item"
        className={classes.gridItem}
      >
        {children}
      </li>
    );
  }
}
