import React, { FC } from 'react';
import { useSettings } from '@wix/tpa-settings/react';
import { StripCard } from 'wix-ui-tpa/cssVars';
import { useEnvironment, useExperiments } from '@wix/yoshi-flow-editor';
import { ServiceLayoutProps } from '../ServiceCard';
import { ImageShapeOptions } from '../../../../../../types/types';
import settingsParams from '../../../../settingsParams';
import { useAddVisibilityClass } from '../../../../hooks/useAddVisibilityClass';
import { classes, st } from './Strip.st.css';
import { DataHooks } from '../consts';
import { useLayoutPerBpInWidget } from '../../../../hooks/useLayoutPbpInWidget';

export const Strip: FC<ServiceLayoutProps> = (props) => {
  const { image, info } = props;
  const { isMobile } = useEnvironment();
  const { layoutStylePerBpEnable: isLayoutPbpEnabled } =
    useLayoutPerBpInWidget();
  const { addVisibilityClassForLayoutPbp } = useAddVisibilityClass();
  const settings = useSettings();

  return (
    <StripCard
      data-hook={DataHooks.STRIP}
      media={image}
      info={info}
      className={addVisibilityClassForLayoutPbp(
        st(classes.root, {
          isMobile,
          imageSize: settings.get(settingsParams.serviceImageSize),
          isLayoutPbpEnabled,
        }),
        classes.serviceImageVisibility,
        'isServiceImageVisible',
      )}
      roundMedia={
        settings.get(settingsParams.serviceImageShape) ===
        ImageShapeOptions.ROUND
      }
    />
  );
};
