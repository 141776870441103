import React, { FC } from 'react';
import { OverlappingCard } from 'wix-ui-tpa/cssVars';
import { useSettings } from '@wix/tpa-settings/react';
import settingsParams from '../../../../settingsParams';
import { useInvertInfoPosition } from '../../../hooks/useInvertInfoPosition';
import { ServiceLayoutProps } from '../ServiceCard';
import { DataHooks } from '../consts';
import { classes, st } from './Overlapping.st.css';
import { useLayoutPerBpInWidget } from '../../../../hooks/useLayoutPbpInWidget';

export const Overlapping: FC<ServiceLayoutProps> = (props) => {
  const { image, info, index } = props;
  const invertInfoPosition = useInvertInfoPosition();
  const { layoutStylePerBpEnable: layoutStylePerBreakPointEnable } =
    useLayoutPerBpInWidget();
  const settings = useSettings();
  const shouldAlternate =
    settings.get(settingsParams.alternateImageInfoSides) &&
    (index ?? 0) % 2 === 1;

  const shouldInvertInfoPosition =
    !layoutStylePerBreakPointEnable && invertInfoPosition
      ? !shouldAlternate
      : shouldAlternate;

  return (
    <OverlappingCard
      data-hook={DataHooks.OVERLAPPING}
      media={image}
      info={info}
      className={st(classes.root, {
        hasImage: Boolean(image),
        invert: invertInfoPosition,
        even: (index ?? 0) % 2 === 0,
        layoutStylePerBreakPointEnable,
      })}
      invertInfoPosition={shouldInvertInfoPosition}
    />
  );
};
