import React from 'react';
import { WidgetActions } from '../../../../actions/actions';

export const WidgetActionsContext = React.createContext<WidgetActions>(
  {} as WidgetActions,
);

export const WidgetActionsConsumer = WidgetActionsContext.Consumer;
export const WidgetActionsProvider = WidgetActionsContext.Provider;

export const useWidgetActions = () => React.useContext(WidgetActionsContext);
