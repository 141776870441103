import {
  FormatterKey,
  FormatterKeysMap,
} from '@wix/bookings-services-preferences-modal/types';

export const servicesPreferencesModalKeysMap: FormatterKeysMap = {
  [FormatterKey.HOUR_UNIT]: 'duration.units.hours',
  [FormatterKey.MINUTE_UNIT]: 'duration.units.minutes',
  [FormatterKey.SELECT_LOCATION_LABEL]:
    'services-preferences-modal.select-location-label',
  [FormatterKey.NEXT_BUTTON]: 'services-preferences-modal.next-button-label',
  [FormatterKey.BACK_BUTTON]: 'services-preferences-modal.back-button-label',
  [FormatterKey.SELECT_STAFF_LABEL]:
    'services-preferences-modal.select-staff-label',
  [FormatterKey.ADD_ANOTHER_SERVICE_BUTTON]:
    'services-preferences-modal.add-another-service',
  [FormatterKey.SUBMIT_BUTTON]: 'services-preferences-modal.submit-button',
  [FormatterKey.ADD_ANOTHER_SERVICE_DESCRIPTION]:
    'services-preferences-modal.add-another-service-label',
  [FormatterKey.PRICE_RANGE]: 'services-preferences-modal.price-range',
  [FormatterKey.NUMBER_OF_SERVICES]:
    'services-preferences-modal.number-of-services',
  [FormatterKey.SELECTED_STAFF]: 'services-preferences-modal.selected-staff',
  [FormatterKey.CONFIRMATION_STEP_TITLE]:
    'services-preferences-modal.confirmation-step-title',
  [FormatterKey.CONFIRMATION_STEP_TEXT]:
    'services-preferences-modal.confirmation-step-text',
  [FormatterKey.CONFIRMATION_STEP_LEAVE_BUTTON]:
    'services-preferences-modal.confirmation-step-leave-button',
  [FormatterKey.CONFIRMATION_STEP_STAY_BUTTON]:
    'services-preferences-modal.confirmation-step-stay-button',
  [FormatterKey.SELECT_ANY_STAFF]:
    'services-preferences-modal.select-any-staff',
  [FormatterKey.LOADING_TEXT]: 'services-preferences-modal.loading-text',
  [FormatterKey.SUMMARY_ANY_STAFF]:
    'services-preferences-modal.summary.select-any-staff',
  [FormatterKey.SELECT_CUSTOM_PREFERENCE_PLACEHOLDER]:
    'services-preferences-modal.select-custom-preference-placeholder',
  [FormatterKey.SELECT_CUSTOM_PREFERENCE_ERROR]:
    'services-preferences-modal.select-custom-preference-error',
  [FormatterKey.SERVICE_PREFERENCES]:
    'services-preferences-modal.basket-service-preferences',
  [FormatterKey.DELETE_SERVICE_BUTTON_ARIA_LABEL]:
    'services-preferences-modal.delete-basket-item-button-aria',
  [FormatterKey.EDIT_SERVICE_BUTTON_ARIA_LABEL]:
    'services-preferences-modal.edit-basket-item-button-aria',
  [FormatterKey.CLOSE_CONFIRMATION_MODAL_BUTTON_ARIA_LABEL]:
    'services-preferences-modal.cancel-confirmation.close-button-aria-label',
  [FormatterKey.CLOSE_MODAL_BUTTON_ARIA_LABEL]:
    'services-preferences-modal.close-button-aria-label',
  [FormatterKey.SERVICE_PREFERENCES_TITLE]:
    'services-preferences-modal.select-your-services',
  [FormatterKey.ERROR_FETCHING_DATA]:
    'services-preferences-modal.error-fetching-data',
  [FormatterKey.BASKET_STEP_TITLE]:
    'services-preferences-modal.basket-step-title',
  [FormatterKey.PREFERENCES_STEP_TITLE]:
    'services-preferences-modal.preferences-step-title',
  [FormatterKey.SERVICE_SELECTION_STEP_TITLE]:
    'services-preferences-modal.service-selection-step-title',
  [FormatterKey.PAYMENT_PRICING_PLAN_DESCRIPTION]:
    'services-preferences-modal.payment-pricing-plan-description',
  [FormatterKey.CAPITALIZED_PAYMENT_PRICING_PLAN_DESCRIPTION]:
    'services-preferences-modal.payment-pricing-plan-description-capitalized',
  [FormatterKey.PAYMENTS_SEPARATOR]:
    'services-preferences-modal.payments-separator',
  [FormatterKey.SEARCH_PLACEHOLDER]:
    'services-preferences-modal.search-placeholder',
  [FormatterKey.NO_SEARCH_RESULTS_TITLE]:
    'services-preferences-modal.no-search-results-title',
  [FormatterKey.NO_SEARCH_RESULTS_SUBTITLE]:
    'services-preferences-modal.no-search-results-subtitle',
  [FormatterKey.DEFAULT_DURATION_LABEL]: 'preferences.duration.default-label',
  [FormatterKey.DURATION_RANGE]: 'duration.range',
};
