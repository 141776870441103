import React, { FC, memo, useCallback } from 'react';
import {
  useEnvironment,
  useExperiments,
  useTranslation,
} from '@wix/yoshi-flow-editor';
import { useSettings } from '@wix/tpa-settings/react';
import { useWidgetActions } from '../../../hooks/useWidgetActions';
import { useWidgetViewModel } from '../../../hooks/useWidgetViewModel';
import { useServiceInfoLayout } from '../../../hooks/useServiceInfoLayout';
import { Divider as TPADivider } from 'wix-ui-tpa/cssVars';
import { MoreInfoButton as MoreInfoButtonComponent } from './MoreInfoButton/MoreInfoButton';
import { OnlineBadge as OnlineBadgeComponent } from './OnlineBadge/OnlineBadge';
import { TagLine } from './TagLine/TagLine';
import { Title } from './Title/Title';
import { Details } from './Details/Details';
import {
  EnrichedService,
  ServiceClickTarget,
} from '../../../../../../types/types';
import { classes, st } from './ServiceInfo.st.css';
import settingsParams from '../../../../settingsParams';
import {
  BookButton as BookButtonComponent,
  BookButtonProps,
} from './BookButton/BookButton';
import {
  DataHooks,
  ServiceDetailsType,
  ServiceInfoLayoutOption,
} from './consts';
import { generateServiceInfoDetails } from '../../../../../../utils/serviceDetails/displayOptions';
import { ReferralInfo } from '../../../../../../utils/bi/consts';
import { CourseAvailability } from './CourseAvailability/CourseAvailability';
import { ExplorePlans } from './ExplorePlans/ExplorePlans';
import { useVisibilityCheck } from '../../../../hooks/useVisibilityCheck';
import { useAddVisibilityClass } from '../../../../hooks/useAddVisibilityClass';
import { ServiceType } from '@wix/ambassador-bookings-services-v2-service/types';
import { SERVICE_INFO_LABEL_ID } from '../../consts';
import { isServiceWithDynamicDuration } from '@wix/bookings-calendar-catalog-viewer-mapper';

export type ServiceInfoProps = {
  service: EnrichedService;
  statusInfo?: React.ReactNode;
  availability?: React.ReactNode;
};

export const ServiceInfo: FC<ServiceInfoProps> = ({ service }) => {
  const serviceInfoLayout = useServiceInfoLayout();
  const { businessInfo, coursesAvailability } = useWidgetViewModel();
  const { language } = useEnvironment();
  const { t } = useTranslation();
  const { shouldBeVisible } = useVisibilityCheck();
  const { experiments } = useExperiments();
  const { onServiceClick } = useWidgetActions();
  const shouldNavigateImmediately = useShouldNavigateImmediately(service);
  const [isNavigatingToService, setIsNavigatingToService] =
    React.useState(false);

  const isTagLineVisible = shouldBeVisible('isTagLineVisible');
  const isServiceOfferedDaysVisible = shouldBeVisible(
    'isServiceOfferedDaysVisible',
  );
  const isServiceStartDateVisible = shouldBeVisible(
    'isServiceStartDateVisible',
  );
  const isServiceDurationVisible = shouldBeVisible('isServiceDurationVisible');
  const isServicePriceVisible = shouldBeVisible('isServicePriceVisible');

  const isDynamicDurationUOUEnable = experiments.enabled(
    'specs.bookings.dynamicDurationUoU',
  );

  const displayOptions = generateServiceInfoDetails({
    service,
    displayOptions: {
      isServiceOfferedDaysVisible,
      isServiceStartDateVisible,
      isServicePriceVisible,
      isServiceDurationVisible,
      isTagLineVisible,
    },
    businessInfo,
    language,
    t,
    coursesAvailability,
    isDynamicDurationUOUEnable,
  });

  const {
    tagLine,
    offeredDays,
    duration,
    price,
    isPassedEndDate,
    isTooLateToBook,
    numberOfSpotsLeft,
    startDate,
  } = displayOptions;

  const titleUrl = useTitleHrefUrl(service);
  const titleTabIndex = useTitleTabIndex(service);
  const isCourseType = service.type === ServiceType.COURSE;
  const courseStatus = isServiceStartDateVisible ? (
    startDate ? (
      <StartDate
        startDate={startDate}
        locale={businessInfo.language}
        ellipsis={serviceInfoLayout === ServiceInfoLayoutOption.HORIZONTAL}
      />
    ) : (
      <Ended isEnded={!!isPassedEndDate} />
    )
  ) : null;

  return (
    <div
      data-id={service.id}
      data-hook={DataHooks.ROOT}
      aria-labelledby={SERVICE_INFO_LABEL_ID}
      role="group"
      className={classes.root}
    >
      <div className={classes.details}>
        <div className={classes.titleArea}>
          <Title
            tabIndex={titleTabIndex}
            ariaDescribedBy={tagLine ? `${service.id}-tagLine` : undefined}
            title={service.name!}
            url={titleUrl}
            onClick={() => {
              if (shouldNavigateImmediately) {
                setIsNavigatingToService(true);
              }
              void onServiceClick({
                service,
                referralInfo: ReferralInfo.SERVICE_TITLE,
              });
            }}
          />
          <OnlineBadge isOfferedOnline={service.conferencing?.enabled!} />
          {tagLine ? (
            <TagLine id={`${service.id}-tagLine`} tagLine={tagLine} />
          ) : null}
          <MoreInfoButton service={service} />
          <Divider />
        </div>
        <div className={classes.priceArea}>
          {offeredDays ? (
            <OfferedDays offeredDays={offeredDays} ellipsis />
          ) : null}
          {courseStatus}
          {duration ? <Duration duration={duration} /> : null}
          {price?.displayedPrice ? (
            <Price
              displayedPrice={price.displayedPrice}
              srOnlyPrice={price.srOnlyPrice!}
            />
          ) : null}
          {isCourseType && (
            <CourseAvailability
              numberOfSpotsLeft={numberOfSpotsLeft}
              checkDivider={false}
              isTooLateToBook={!!isTooLateToBook}
              isPassedEndDate={!!isPassedEndDate}
              className={classes.HorizontalCourseAvailability}
            />
          )}
        </div>
        {isCourseType && (
          <CourseAvailability
            numberOfSpotsLeft={numberOfSpotsLeft}
            checkDivider={true}
            isTooLateToBook={!!isTooLateToBook}
            isPassedEndDate={!!isPassedEndDate}
            className={classes.VerticalCourseAvailability}
          />
        )}
      </div>
      <ServiceActions
        isTooLateToBook={isTooLateToBook}
        numberOfSpotsLeft={numberOfSpotsLeft}
        service={service}
        loading={isNavigatingToService}
      />
    </div>
  );
};

const OnlineBadge = ({ isOfferedOnline }: { isOfferedOnline?: Boolean }) => {
  const { shouldBeVisible } = useVisibilityCheck();
  const showBadge = isOfferedOnline && shouldBeVisible('isOnlineBadgeVisible');
  return showBadge ? <OnlineBadgeComponent /> : null;
};

const MoreInfoButton: FC<Pick<ServiceInfoProps, 'service'>> = ({ service }) => {
  const { shouldBeVisible } = useVisibilityCheck();
  const { onMoreInfoClick } = useWidgetActions();
  return shouldBeVisible('isMoreInfoButtonVisible') ? (
    <MoreInfoButtonComponent
      url={service.urls?.servicePage?.url!}
      onClick={() =>
        onMoreInfoClick({
          service,
          referralInfo: ReferralInfo.MORE_INFO_BUTTON,
        })
      }
    />
  ) : null;
};

const Divider = () => {
  const { shouldBeVisible } = useVisibilityCheck();
  const { addVisibilityClass } = useAddVisibilityClass();
  return shouldBeVisible('isServiceDividerVisible') ? (
    <TPADivider
      data-hook={DataHooks.DIVIDER}
      className={addVisibilityClass(
        classes.divider,
        classes.dividerVisibility,
        'isServiceDividerVisible',
      )}
    />
  ) : null;
};

const OfferedDays = ({
  offeredDays,
  ellipsis = false,
}: {
  offeredDays: string;
  ellipsis?: boolean;
}) => {
  const { addVisibilityClass } = useAddVisibilityClass();
  return (
    <Details
      type={ServiceDetailsType.OFFERED_DAYS}
      ellipsis={ellipsis}
      className={addVisibilityClass(
        '',
        classes.offeredDaysVisibility,
        'isServiceOfferedDaysVisible',
      )}
    >
      {offeredDays}
    </Details>
  );
};

const StartDate = ({
  startDate,
  ellipsis = false,
}: {
  startDate: string;
  locale?: string;
  ellipsis?: boolean;
}) => {
  const { addVisibilityClass } = useAddVisibilityClass();

  return (
    <Details
      type={ServiceDetailsType.START_DATE}
      ellipsis={ellipsis}
      className={addVisibilityClass(
        '',
        classes.startDateVisibility,
        'isServiceStartDateVisible',
      )}
    >
      {startDate}
    </Details>
  );
};

const Ended = ({ isEnded }: { isEnded: boolean }) => {
  const { t } = useTranslation();
  const { addVisibilityClass } = useAddVisibilityClass();
  if (!isEnded) {
    return null;
  }
  return (
    <Details
      className={addVisibilityClass(
        '',
        classes.startDateVisibility,
        'isServiceStartDateVisible',
      )}
    >
      {t('service.schedule.course.end.date.passed')}
    </Details>
  );
};

const Duration = ({
  duration,
  ellipsis = false,
}: {
  duration: string;
  ellipsis?: boolean;
}) => {
  const { addVisibilityClass } = useAddVisibilityClass();
  return (
    <Details
      type={ServiceDetailsType.DURATION}
      ellipsis={ellipsis}
      className={addVisibilityClass(
        '',
        classes.durationVisibility,
        'isServiceDurationVisible',
      )}
    >
      {duration}
    </Details>
  );
};

const Price = ({
  displayedPrice,
  srOnlyPrice,
}: {
  displayedPrice: string;
  srOnlyPrice: string;
}) => {
  const { addVisibilityClass } = useAddVisibilityClass();
  return (
    <>
      <div
        data-hook={DataHooks.SR_ONLY_PRICE}
        className={addVisibilityClass(
          classes.srOnly,
          classes.priceVisibility,
          'isServicePriceVisible',
        )}
      >
        {srOnlyPrice}
      </div>
      <Details
        type={ServiceDetailsType.PRICE}
        ariaHidden
        className={addVisibilityClass(
          '',
          classes.priceVisibility,
          'isServicePriceVisible',
        )}
      >
        {displayedPrice}
      </Details>
    </>
  );
};

const BookButton: FC<BookButtonProps> = memo((props) => {
  const { shouldBeVisible } = useVisibilityCheck();

  return shouldBeVisible('isBookButtonVisible') ? (
    <BookButtonComponent {...props} />
  ) : null;
});

const ServiceActions = ({
  loading,
  service,
  isTooLateToBook,
  numberOfSpotsLeft,
}: {
  isTooLateToBook?: boolean;
  numberOfSpotsLeft?: number;
  loading?: boolean;
  service: EnrichedService;
}) => {
  const { onActionButtonClick } = useWidgetActions();
  const { addVisibilityClass } = useAddVisibilityClass();
  const { shouldBeVisible } = useVisibilityCheck();
  const shouldNavigateImmediately = useShouldNavigateImmediately(service);
  const [isNavigatingToService, setIsNavigatingToService] =
    React.useState(false);
  const showViewCourse = isTooLateToBook || numberOfSpotsLeft! <= 0;

  const onBookButtonClick = useCallback(() => {
    if (shouldNavigateImmediately) {
      setTimeout(() => setIsNavigatingToService(true), 0);
    }
    void onActionButtonClick({
      service,
      referralInfo: ReferralInfo.BOOK_BUTTON,
    });
  }, [service]);

  return shouldBeVisible('isBookButtonVisible') ? (
    <div
      className={addVisibilityClass(
        classes.button,
        classes.buttonVisibility,
        'isBookButtonVisible',
      )}
    >
      <BookButton
        onClick={onBookButtonClick}
        url={
          shouldNavigateImmediately
            ? service.type === ServiceType.COURSE ||
              service.displayOnlyNoBookFlow
              ? service.urls?.servicePage?.url!
              : service.urls?.calendarPage?.url!
            : undefined
        }
        loading={loading || isNavigatingToService}
        isNoBookFlow={service.displayOnlyNoBookFlow}
        isPendingApprovalFlow={service.onlineBooking?.requireManualApproval!}
        showViewCourse={showViewCourse}
      />
      <ExplorePlans service={service} />
    </div>
  ) : (
    <ExplorePlans service={service} onlyLink />
  );
};

const useShouldNavigateImmediately = (service: EnrichedService) => {
  const { isMultiServiceAppointmentEnabled } = useWidgetViewModel();
  const { blockNavigationReason } = service;
  const shouldOpenPreferencesModal =
    (isMultiServiceAppointmentEnabled &&
      service.type === ServiceType.APPOINTMENT) ||
    isServiceWithDynamicDuration(service);

  return !blockNavigationReason && !shouldOpenPreferencesModal;
};

const useTitleHrefUrl = (service: EnrichedService) => {
  const shouldNavigateImmediately = useShouldNavigateImmediately(service);
  const settings = useSettings();

  return service.displayOnlyNoBookFlow ||
    service.type === ServiceType.COURSE ||
    settings.get(settingsParams.serviceClickTarget) ===
      ServiceClickTarget.SERVICE_PAGE
    ? service.urls?.servicePage?.url!
    : shouldNavigateImmediately
    ? service.urls?.calendarPage?.url!
    : undefined;
};

const useTitleTabIndex = (service: EnrichedService) => {
  const settings = useSettings();
  const { shouldBeVisible } = useVisibilityCheck();
  return service.displayOnlyNoBookFlow ||
    service.type === ServiceType.COURSE ||
    settings.get(settingsParams.serviceClickTarget) ===
      ServiceClickTarget.SERVICE_PAGE
    ? shouldBeVisible('isMoreInfoButtonVisible')
      ? -1
      : 0
    : shouldBeVisible('isBookButtonVisible')
    ? -1
    : 0;
};
