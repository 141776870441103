import React, { FC } from 'react';
import { useEnvironment, WidgetProps } from '@wix/yoshi-flow-editor';
import { EmptyState } from './EmptyState/EmptyState';
import { Header } from './Header/Header';
import { Body } from './Body/Body';
import { HiddenServicesForSeo } from './HiddenServicesForSeo/HiddenServicesForSeo';
import { WidgetViewModelProvider } from './hooks/useWidgetViewModel';
import { WidgetActionsProvider } from './hooks/useWidgetActions';
import {
  WidgetErrorStateViewModel,
  WidgetViewModel,
} from '../../../viewModel/viewModel';
import { WidgetActions } from '../../../actions/actions';
import { classes, st } from './Widget.st.css';
import { Dialog } from './Dialog/Dialog';
import { ErrorState } from './ErrorState/ErrorState';
import { Toast, ToastPlacement, ToastSkin } from 'wix-ui-tpa/cssVars';

export type ControllerProps = {
  widgetViewModel: WidgetViewModel;
  widgetErrorStateViewModel?: WidgetErrorStateViewModel;
  widgetActions: WidgetActions;
};

export const Widget: FC<WidgetProps<ControllerProps>> = (props) => {
  const { widgetActions, widgetViewModel, widgetErrorStateViewModel } = props;

  if (widgetErrorStateViewModel) {
    return (
      <WidgetContainer>
        <ErrorState viewModel={widgetErrorStateViewModel} />;
      </WidgetContainer>
    );
  }

  return widgetActions && widgetViewModel ? (
    <WidgetRenderer {...props} />
  ) : null;
};

const WidgetContainer: React.FC = ({ children }) => {
  const { isRTL, isMobile } = useEnvironment();

  return (
    <div
      data-hook="widget"
      className={st(classes.root, {
        isRTL,
        isMobile,
      })}
    >
      {children}
    </div>
  );
};

const WidgetRenderer: FC<WidgetProps<ControllerProps>> = (props) => {
  const { widgetViewModel, widgetActions } = props;
  const { headerViewModel, services, seo, errorMessage } = widgetViewModel;
  const { isMobile } = useEnvironment();

  return (
    <WidgetContainer>
      <WidgetViewModelProvider value={widgetViewModel}>
        <WidgetActionsProvider value={widgetActions}>
          {services.length ? (
            <>
              <Dialog
                widgetViewModel={widgetViewModel}
                widgetActions={widgetActions}
              />

              <Header viewModel={headerViewModel} />
              <Body widgetId={props.id} />
              {seo && seo.shouldListServicesFromOtherCategories ? (
                <HiddenServicesForSeo />
              ) : null}
              {errorMessage && (
                <Toast
                  className={classes.toast}
                  skin={ToastSkin.error}
                  data-hook="error-state-toast"
                  placement={
                    isMobile
                      ? ToastPlacement.bottomFullWidth
                      : ToastPlacement.inline
                  }
                >
                  {errorMessage}
                </Toast>
              )}
            </>
          ) : (
            <EmptyState />
          )}
        </WidgetActionsProvider>
      </WidgetViewModelProvider>
    </WidgetContainer>
  );
};
