import React from 'react';
import { ServiceInfoAlignmentOptions } from '../../../../types/types';

export const VerticalAlignmentContext =
  React.createContext<ServiceInfoAlignmentOptions>(
    ServiceInfoAlignmentOptions.TOP,
  );

export const VerticalAlignmentConsumer = VerticalAlignmentContext.Consumer;
export const VerticalAlignmentProvider = VerticalAlignmentContext.Provider;

export const useVerticalAlignment = () =>
  React.useContext(VerticalAlignmentContext);
