import { useSettings } from '@wix/tpa-settings/react';
import { useEnvironment } from '@wix/yoshi-flow-editor';
import settingsParams from '../settingsParams';
import { VisibilityKey } from './types';

export function useAddVisibilityClass() {
  const { isCssPerBreakpoint } = useEnvironment();
  const settings = useSettings();
  const isLayoutPbpEnable = true;

  return {
    addVisibilityClass: (
      className: string,
      visibilityClassName: string,
      key: VisibilityKey,
    ) =>
      isCssPerBreakpoint &&
      typeof settings.get(settingsParams[key]) !== 'boolean'
        ? className
          ? `${className} ${visibilityClassName}`
          : visibilityClassName
        : className,
    addVisibilityClassWithoutCondition: (
      className: string,
      visibilityClassName: string,
    ) =>
      className ? `${className} ${visibilityClassName}` : visibilityClassName,
    addVisibilityClassForLayoutPbp: (
      className: string,
      visibilityClassName: string,
      key: VisibilityKey,
    ) => {
      if (isLayoutPbpEnable) {
        return className
          ? `${className} ${visibilityClassName}`
          : visibilityClassName;
      } else {
        return isCssPerBreakpoint &&
          typeof settings.get(settingsParams[key]) !== 'boolean'
          ? className
            ? `${className} ${visibilityClassName}`
            : visibilityClassName
          : className;
      }
    },
  };
}
