import React, { FC } from 'react';
import { WidgetErrorStateViewModel } from '../../../../viewModel/viewModel';
import { Toast, ToastPlacement, ToastSkin } from 'wix-ui-tpa/cssVars';
import { Header } from '../Header/Header';
import { classes } from './ErrorState.st.css';
import { useEnvironment } from '@wix/yoshi-flow-editor';

export interface ErrorStateProps {
  viewModel: WidgetErrorStateViewModel;
}

export const ErrorState: FC<ErrorStateProps> = ({
  viewModel: { headerViewModel, errorText },
}) => {
  const { isMobile } = useEnvironment();

  return (
    <div className={classes.root} data-hook="service-list-error-state">
      <div className={classes.toastContainer}>
        <Toast
          className={classes.toast}
          skin={ToastSkin.error}
          shouldShowCloseButton
          data-hook="error-state-toast"
          placement={
            isMobile ? ToastPlacement.bottomFullWidth : ToastPlacement.inline
          }
        >
          {errorText}
        </Toast>
      </div>
      <Header viewModel={headerViewModel} />
    </div>
  );
};
