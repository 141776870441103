import React, { FC } from 'react';
import { useEnvironment, useTranslation } from '@wix/yoshi-flow-editor';
import { useSettings } from '@wix/tpa-settings/react';
import { TextButton } from 'wix-ui-tpa/cssVars';
import { classes, st } from './ExplorePlans.st.css';
import settingsParams from '../../../../../settingsParams';
import { DataHooks } from './consts';
import { useWidgetActions } from '../../../../hooks/useWidgetActions';
import { ReferralInfo } from '../../../../../../../utils/bi/consts';
import { useWidgetViewModel } from '../../../../hooks/useWidgetViewModel';
import { showExplorePlansForService } from '../../../../../../../utils/serviceDetails/showExplorePlansForService';
import {
  EnrichedService,
  ServiceListLayoutOptions,
} from '../../../../../../../types/types';
import { useAddVisibilityClass } from '../../../../../hooks/useAddVisibilityClass';
import { useLayoutPerBpInWidget } from '../../../../../hooks/useLayoutPbpInWidget';

export interface ExplorePlansProps {
  service: EnrichedService;
  onlyLink?: boolean;
}

export const ExplorePlans: FC<ExplorePlansProps> = ({
  service,
  onlyLink = false,
}) => {
  const { isMobile } = useEnvironment();
  const settings = useSettings();
  const { t } = useTranslation();
  const {
    serviceListLayout,
    bodyViewModel: { atLeastOneServiceHasExplorePlansLink },
  } = useWidgetViewModel();
  const { navigateToPricingPlan } = useWidgetActions();
  const { addVisibilityClass } = useAddVisibilityClass();
  const { layoutStylePerBpEnable: isLayoutPBPEnabled, layoutPbpPhase2Enabled } =
    useLayoutPerBpInWidget();
  if (!atLeastOneServiceHasExplorePlansLink) {
    return null; // if no service shows an Explore Plans link, we don't need to render anything. But if at least one service shows an Explore Plans link, we need to render a placeholder hidden link for services without the link, so the Book buttons are aligned on all services.
  }

  const linkText = settings.get(settingsParams.explorePlansText);
  const showLink = showExplorePlansForService(service);
  const isStripLayout = serviceListLayout === ServiceListLayoutOptions.STRIP;
  const isGridLayout = serviceListLayout === ServiceListLayoutOptions.GRID;
  const renderLink = showLink || isStripLayout || (isGridLayout && !isMobile);
  const ignoreHeight = !showLink && isStripLayout;

  return layoutPbpPhase2Enabled || renderLink ? (
    <div
      className={addVisibilityClass(
        st(classes.root, {
          ignoreHeight,
          isStripLayout,
          onlyLink,
          showLink,
          layoutPbpPhase2Enabled,
        }),
        classes.explorePlansVisibility,
        'isExplorePlansVisible',
      )}
    >
      <TextButton
        onClick={() =>
          navigateToPricingPlan(service, ReferralInfo.EXPLORE_PLANS)
        }
        className={st(classes.link, {
          isMobile,
          isHidden: !showLink,
          isLayoutPBPEnabled,
          alignment: settings.get(settingsParams.textAlignment),
        })}
        data-hook={DataHooks.LINK}
      >
        {linkText}
      </TextButton>
    </div>
  ) : null;
};
