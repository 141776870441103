import React, { FC } from 'react';
import { useTranslation } from '@wix/yoshi-flow-editor';
import { useSettings } from '@wix/tpa-settings/react';
import { Badge, BadgePriority as BADGE_PRIORITY } from 'wix-ui-tpa/cssVars';
import { VideoFillXSmall as Camera } from '@wix/wix-ui-icons-common/on-stage/system';
import settingsParams from '../../../../../settingsParams';
import { useAddVisibilityClass } from '../../../../../hooks/useAddVisibilityClass';
import { classes } from './OnlineBadge.st.css';
import { DataHooks } from './consts';

export const OnlineBadge: FC = () => {
  const settings = useSettings();
  const { t } = useTranslation();
  const { addVisibilityClass } = useAddVisibilityClass();

  return (
    <Badge
      className={addVisibilityClass(
        classes.root,
        classes.badgeVisibility,
        'isOnlineBadgeVisible',
      )}
      key={DataHooks.ROOT}
      data-hook={DataHooks.ROOT}
      icon={<Camera />}
      priority={BADGE_PRIORITY.light}
    >
      {settings.get(settingsParams.onlineBadgeText)}
    </Badge>
  );
};
