export interface GridProps {
  children?: React.ReactNode[];
  showRowDivider: boolean;
  uniformRowHeight: boolean;
  maxColumns?: number;
  minColumnWidth?: number;
  maxColumnWidth?: number;
  width?: number;
  rowGap?: number;
  columnGap?: number;
  dividerWidth?: string | number;
  className?: string;
  id?: string;
  isMobile: boolean;
  'data-hook'?: string;
  isCssPerBreakpoint?: boolean;
}

export interface GridDefaultProps {
  children: React.ReactNode[];
  showRowDivider: boolean;
  uniformRowHeight: boolean;
  maxColumns: number;
  width: number;
  minColumnWidth: number;
  dividerWidth: string;
}

export enum GridDataHooks {
  container = 'Grid-container',
  item = 'Grid-item',
}

export enum GridDataKeys {
  itemsPerRow = 'data-items-per-row',
  itemMaxWidth = 'data-item-max-width',
  dividerWidth = 'data-divider-width',
  rowGap = 'data-row-gap',
  columnGap = 'data-column-gap',
  rowSpan = 'data-row-span',
  columnSpan = 'data-column-span',
  maxColumnWidth = 'data-max-column-width',
  minColumnWidth = 'data-min-column-width',
}

export interface GridItemProps {
  colSpan?: number;
  rowSpan?: number;
  focused?: boolean;
}

export interface GridItemDefaultProps {
  colSpan: number;
  rowSpan: number;
}

export interface GridStyleProps {
  isFullWidth: boolean;
  maxColumns: number;
  minColumnWidth: number;
  maxColumnWidth: string;
  columnGap: number;
  rowGap: number;
  dividerWidth: string;
  cssStateDivider: string;
  gridId: string;
  gridTemplateColumns: string;
}
